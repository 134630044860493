import React from 'react';
import './User.css';


const User = (props) => {
    return ( 
        <li className="User">{props.user.username} <a onClick={() => props.showUser(props.user)}>show</a></li>
     );
}
 
export default User;