import React from 'react';
import './AnimatedLogo.css';
import renderHTML from 'react-render-html';

const AnimatedLogo = (props) => {

return (
<div className="animain">
  <div className="screen">
    <header>
      <svg id="shadow" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 347.76 23.19">
        <title>logo</title>
        <ellipse id="Ellipse1" cx={39} cy="11.6" rx={39} ry="11.6" style={{fill: '#f2f2f2'}} />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 945.05 430.96">
        <title>5WayChallenge-symbol</title>
        <g style={{isolation: 'isolate'}}>
          <g id="logo">
            <g id="person">
              <circle id="head" cx="304.84" cy="226.77" r="41.5" transform="translate(-112.49 280.8) rotate(-75.68)" style={{fill: '#00abb5', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="body" d="M252.46,387.54c-31.78,34.2-70.14,47.13-85.66,28.87s-2.35-60.79,29.44-95,70.14-47.13,85.66-28.87S284.25,353.33,252.46,387.54Z" transform="translate(-122.23 -185.26)" style={{fill: '#23826a', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="legs" d="M252,391.32c40,26.7,64.56,65.62,54.86,86.93s-50,17-90-9.74S152.28,402.89,162,381.57,212,364.62,252,391.32Z" transform="translate(-122.23 -185.26)" style={{fill: '#e76c24', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="shin" d="M267.66,548.2c-34.6,35.77-74.1,50.56-88.22,33s2.47-60.72,37.07-96.49,74.1-50.56,88.22-33S302.26,512.43,267.66,548.2Z" transform="translate(-122.23 -185.26)" style={{fill: '#ed1378', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
              <path id="hands" d="M184.87,330.59c44.23,15.16,89,10.13,100-11.23s-16-51-60.19-66.13-89-10.13-100,11.23S140.64,315.43,184.87,330.59Z" transform="translate(-122.23 -185.26)" style={{fill: '#442062', opacity: '0.8300000000000001', mixBlendMode: 'multiply'}} />
            </g>
            <g id="_5_ways_to_wellbeing_challenge" data-name="5 ways to wellbeing  challenge">
              <g id="_1" data-name={1}>
                <path d="M448.15,356.77a42.63,42.63,0,0,1-13-1.76,24.15,24.15,0,0,1-8.7-4.64,19.69,19.69,0,0,1-5.14-7.65,39.18,39.18,0,0,1-2.5-9.71,98.54,98.54,0,0,1-.61-11.84h24.56a65.52,65.52,0,0,0,.64,10.69q.64,3.59,2.13,5a5.47,5.47,0,0,0,3.79,1.22H450q4-.27,4.94-5t.95-17.36a70.29,70.29,0,0,0-.3-7,31.06,31.06,0,0,0-1.08-5.62,8.45,8.45,0,0,0-2.33-4,5.59,5.59,0,0,0-3.86-1.39,6.21,6.21,0,0,0-5.21,2.47q-1.9,2.47-2.57,8.09h-22.2l2.57-65.91h53.66v20.57h-33.5l-1.42,20.91q1.08-1.56,4.8-3a34.55,34.55,0,0,1,8.12-2,27.86,27.86,0,0,1,3.79-.27,19.17,19.17,0,0,1,16.11,8.12q7.17,9.61,7.17,28.69,0,5.62-.3,10a75.89,75.89,0,0,1-1.15,8.83,34.81,34.81,0,0,1-2.3,7.71,28.4,28.4,0,0,1-3.82,6.19,19.15,19.15,0,0,1-5.65,4.77,29.87,29.87,0,0,1-7.88,2.88A47.11,47.11,0,0,1,448.15,356.77Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M537.68,354.47l-12-112.2H549l5.14,60.9,6.5-60.9h18l7.11,60.3,5.28-60.3h23.08L602,354.47H577.33l-7.78-63.34-7.11,63.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M618.82,354.47l20.37-112.2h31.94l20.17,112.2H666.19l-3.52-22.4H648.12l-3.38,22.4Zm31.47-38.23h10.08l-5.08-41.89Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M711.8,354.47V306.76l-20.5-64.49h25.92l8.86,30,7.38-30h24.83l-20.17,64.49v47.71Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M798.15,356.23q-16.65,0-24.53-8.39t-8.36-28l23.28-3a60.18,60.18,0,0,0,.61,9.34,17.39,17.39,0,0,0,1.76,5.79,7.72,7.72,0,0,0,2.47,2.81,5.75,5.75,0,0,0,3.08.81q4.6,0,4.6-6.63a20.51,20.51,0,0,0-2.94-11.17,47.77,47.77,0,0,0-9.44-10.22l-7.11-6q-3.86-3.31-6.33-5.89a47.6,47.6,0,0,1-5-6.33,27.35,27.35,0,0,1-3.76-8.29,38.82,38.82,0,0,1-1.22-10.08q0-14.55,8.76-22.43t24.19-8q27.61,0,27.81,32.75l-23.82,2.37q0-4.4-.1-6.6a29.71,29.71,0,0,0-.58-4.53,5.75,5.75,0,0,0-1.52-3.25,4.13,4.13,0,0,0-2.81-.91,4.21,4.21,0,0,0-3.79,2.64,11.54,11.54,0,0,0-1.29,5.21,16,16,0,0,0,.24,2.77q.24,1.35.44,2.4a8.15,8.15,0,0,0,.88,2.3q.68,1.25,1,2a9.62,9.62,0,0,0,1.35,2q1,1.22,1.35,1.69A15.25,15.25,0,0,0,799,283q1.28,1.22,1.56,1.46l1.76,1.56,1.62,1.46,6.5,5.75a89.3,89.3,0,0,1,7,7,56,56,0,0,1,5.52,7.48,33.53,33.53,0,0,1,4,9.2,41.75,41.75,0,0,1,1.32,10.73A27.62,27.62,0,0,1,820,348Q811.68,356.23,798.15,356.23Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M886.93,354.47v-91.9H870.56v-20.3h60.16v20.3H914.41v91.9Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M990.37,353.76a55.64,55.64,0,0,1-32.79,0,24.19,24.19,0,0,1-10.76-7.38,30.32,30.32,0,0,1-5.75-11.57,62.09,62.09,0,0,1-1.76-15.73V277.66a62.1,62.1,0,0,1,1.76-15.73,30.34,30.34,0,0,1,5.75-11.57A24.21,24.21,0,0,1,957.58,243a55.63,55.63,0,0,1,32.79,0,24.43,24.43,0,0,1,10.83,7.38,30.47,30.47,0,0,1,5.82,11.57,61,61,0,0,1,1.79,15.73v41.42a61,61,0,0,1-1.79,15.73,30.45,30.45,0,0,1-5.82,11.57A24.42,24.42,0,0,1,990.37,353.76ZM974,337.21a6.29,6.29,0,0,0,3.65-1,5.34,5.34,0,0,0,2-2.88,17.62,17.62,0,0,0,.78-3.45,36.78,36.78,0,0,0,.17-3.86V270.63a36.81,36.81,0,0,0-.17-3.86,17,17,0,0,0-.78-3.42,5.35,5.35,0,0,0-2-2.84,6.3,6.3,0,0,0-3.65-1,6.11,6.11,0,0,0-3.11.74,5.59,5.59,0,0,0-1.93,1.73,7.63,7.63,0,0,0-1,2.71,18,18,0,0,0-.37,2.84q0,1.12,0,3.08V326q0,1.9,0,3a18,18,0,0,0,.37,2.88,7.62,7.62,0,0,0,1,2.71,5.95,5.95,0,0,0,5,2.54Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
              </g>
              <g id="_2" data-name={2}>
                <path d="M425.14,484.46l-12-112.2h23.28l5.14,60.9,6.5-60.9h18l7.11,60.3,5.28-60.3h23.08l-12.11,112.2H464.8L457,421.12l-7.11,63.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M513.18,484.46V372.26H565.7v18.41h-25v25h18.68v19.08H540.66v31.33H566v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M576.86,484.46V372.26h27.47v93.86h25v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M640.07,484.46V372.26h27.47v93.86h25v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M703.27,484.46V372.26h30.38q4.47,0,8.05.24a62.92,62.92,0,0,1,7.65,1,35.6,35.6,0,0,1,7.11,2.06,26.48,26.48,0,0,1,6,3.55,19.27,19.27,0,0,1,4.74,5.31A29.57,29.57,0,0,1,770,392a37.24,37.24,0,0,1,.95,8.73V402q-.41,18.88-16.44,22.2,9.54.88,14.72,7.75t5.38,18.78v1q0,16.85-8.93,24.63-9.2,8.05-26.46,8.05Zm27.47-67.87h3.65q7.44,0,10-3.42T747,402.78a12.85,12.85,0,0,0-3.18-8.9,10.88,10.88,0,0,0-8.53-3.55h-4.53Zm0,48.72h3.72q7.44,0,10.56-3.72t3.11-11.3q0-9.07-3-12.62t-10.56-3.55h-3.86Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M787.46,484.46V372.26H840v18.41h-25v25h18.68v19.08H814.93v31.33h25.31v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M851.2,484.46V372.26h27.41v112.2Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M894.92,484.46V372.26h19.49l20.5,48v-48h22.67v112.2H938.84l-20.37-52.78v52.78Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M1003.94,486.22a35.8,35.8,0,0,1-15-2.84,22.58,22.58,0,0,1-9.75-8.19A37.27,37.27,0,0,1,974.13,463a70,70,0,0,1-1.59-15.77V409.48a64.52,64.52,0,0,1,1.79-16.07,34.16,34.16,0,0,1,5.75-12.18,24.65,24.65,0,0,1,10.79-8,44.17,44.17,0,0,1,16.44-2.74,49.7,49.7,0,0,1,13.5,1.66,29.24,29.24,0,0,1,9.64,4.5,23.22,23.22,0,0,1,6.23,7.07,30.71,30.71,0,0,1,3.45,9,51.5,51.5,0,0,1,1,10.63v8.05h-27V400.69q0-1.89-.07-3.08a22.27,22.27,0,0,0-.41-2.91,7.7,7.7,0,0,0-1-2.71,5.62,5.62,0,0,0-1.93-1.73,6.12,6.12,0,0,0-3.11-.74,6.46,6.46,0,0,0-3.72,1,5.56,5.56,0,0,0-2.13,2.88,17.21,17.21,0,0,0-.85,3.52,32.4,32.4,0,0,0-.2,3.89v55a21,21,0,0,0,.34,3.89,16.12,16.12,0,0,0,1.12,3.55,6.37,6.37,0,0,0,2.37,2.81,6.78,6.78,0,0,0,3.82,1,6.62,6.62,0,0,0,4.84-1.89,9.35,9.35,0,0,0,2.57-4.3,20,20,0,0,0,.64-5.18V441.15h-9V425.39h33.7v59.08h-18.34l-1.35-8.19Q1016.52,486.22,1003.94,486.22Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
              </g>
              <g id="_3" data-name={3}>
                <path d="M451.13,616.22a47.08,47.08,0,0,1-16.41-2.54A24.06,24.06,0,0,1,424,606.13a31.94,31.94,0,0,1-5.75-11.71,61.39,61.39,0,0,1-1.79-15.77V538.12a61.71,61.71,0,0,1,1.79-15.83A31.94,31.94,0,0,1,424,510.58,24.06,24.06,0,0,1,434.72,503a47.08,47.08,0,0,1,16.41-2.54,51.34,51.34,0,0,1,13.47,1.59,28.36,28.36,0,0,1,9.58,4.4,22.5,22.5,0,0,1,6.12,7,30.32,30.32,0,0,1,3.35,8.9,52.23,52.23,0,0,1,1,10.56V543h-27V530.68q0-2.44-.14-3.89a18,18,0,0,0-.71-3.38,5.2,5.2,0,0,0-2-2.91,7.17,7.17,0,0,0-6.8-.24,5.7,5.7,0,0,0-2,1.76,7.94,7.94,0,0,0-1,2.71,22.14,22.14,0,0,0-.47,3q-.1,1.29-.1,3V586q0,1.76.1,3.08a18.67,18.67,0,0,0,.51,3,8.88,8.88,0,0,0,1.08,2.71,5.28,5.28,0,0,0,2,1.76,6.4,6.4,0,0,0,3.08.71,6.29,6.29,0,0,0,3.65-1,5.2,5.2,0,0,0,2-2.94,18.64,18.64,0,0,0,.71-3.42q.13-1.45.14-3.89V573.11h27v10a53.1,53.1,0,0,1-1,10.66,31.62,31.62,0,0,1-3.35,9,22.86,22.86,0,0,1-6.12,7.14,28.85,28.85,0,0,1-9.58,4.57A48.39,48.39,0,0,1,451.13,616.22Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M498.36,614.46V502.26h27.41v44.39h14V502.26h27.48v112.2H539.78V566.34h-14v48.11Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M579.16,614.46l20.37-112.2h31.94l20.17,112.2H626.53L623,592.06H608.46l-3.38,22.4Zm31.47-38.23h10.08l-5.08-41.89Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M662.94,614.46V502.26h27.47v93.86h25v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M726.15,614.46V502.26h27.47v93.86h25v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M789.35,614.46V502.26h52.51v18.41h-25v25H835.5v19.08H816.83v31.33h25.31v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M853,614.46V502.26h19.49l20.5,48v-48h22.67v112.2H896.95l-20.37-52.78v52.78Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M962,616.22a35.8,35.8,0,0,1-15-2.84,22.58,22.58,0,0,1-9.75-8.19A37.27,37.27,0,0,1,932.24,593a70,70,0,0,1-1.59-15.77V539.48a64.52,64.52,0,0,1,1.79-16.07,34.16,34.16,0,0,1,5.75-12.18,24.65,24.65,0,0,1,10.79-8,44.17,44.17,0,0,1,16.44-2.74,49.72,49.72,0,0,1,13.5,1.66,29.24,29.24,0,0,1,9.64,4.5,23.2,23.2,0,0,1,6.23,7.07,30.71,30.71,0,0,1,3.45,9,51.5,51.5,0,0,1,1,10.62v8.05h-27V530.68q0-1.89-.07-3.08a22.27,22.27,0,0,0-.41-2.91,7.7,7.7,0,0,0-1-2.71,5.62,5.62,0,0,0-1.93-1.73,6.12,6.12,0,0,0-3.11-.74,6.46,6.46,0,0,0-3.72,1,5.56,5.56,0,0,0-2.13,2.88,17.21,17.21,0,0,0-.85,3.52,32.39,32.39,0,0,0-.2,3.89v55a21,21,0,0,0,.34,3.89,16.12,16.12,0,0,0,1.12,3.55,6.37,6.37,0,0,0,2.37,2.81,6.78,6.78,0,0,0,3.82,1,6.62,6.62,0,0,0,4.84-1.89,9.35,9.35,0,0,0,2.57-4.3,20,20,0,0,0,.64-5.18V571.15h-9V555.38h33.7v59.08H980.93l-1.35-8.19Q974.64,616.22,962,616.22Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
                <path d="M1014.5,614.46V502.26H1067v18.41h-25v25h18.68v19.08H1042v31.33h25.31v18.34Z" transform="translate(-122.23 -185.26)" style={{fill: '#6d6e71'}} />
              </g>
            </g>
          </g>
        </g>
      </svg>
    </header>
  </div>
</div>

)

}

export default AnimatedLogo;