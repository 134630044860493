import React, { useEffect, useState } from 'react';
import './Company.css';
import superApi from '../../../../Api/Api';
import { Button } from 'primereact/button';
import MaterialTable from 'material-table';


var moment = require('moment');


const Company = (props) => {

    useEffect(() => {
        superApi.get('company/' + props.match.params.id).then(response => {
            setCompany(response.data)
            return response.data._id
        }).catch(error => setFail(true))
            .then(response => {
                superApi.get('api/admin/tasks/' + response).then(response => {
                    setContenders(response.data)
                    setIsLoading(false)
                }).catch(error => setFail(true))
            })
    }, [])

    const [company, setCompany] = useState(null)
    const [contenders, setContenders] = useState([])
    const [fail, setFail] = useState(false)
    const [isLoading, setIsLoading] = useState(true)

    let selectedRows = []
    const handleRowSelect = (e) => {
        selectedRows = e.selectedRows;
    }

    const handleDelete = (row) => {
        superApi.patch('api/admin/tasks/' + company._id, { 'items': [row] }).then(response => {
            console.log(response.data); // setContenders()
            setContenders(response.data)
        })
    }
    const columns = [
        {
            title: 'Username',
            field: 'username',
            sortable: true,

        },
        {
            title: 'Title',
            field: 'title',
            sortable: true,
        },
        {
            title: 'Date',
            field: 'date',
            sortable: true,
        },
        {
            title: 'Note',
            field: 'note',
            sortable: true,
        },
        {
            title: 'Points',
            field: 'points',
            type: 'numeric',
        },
        {
            title: 'Bonus',
            field: 'bonus',
            
        },


    ]
    if (!isLoading) {


        let tableData = []
        const CONTENDERS = contenders.map(contender => {
            const current_username = contender.username;
            console.log(current_username)
            return contender.profile.achievements.map((achievement, k) => {
                
                let current_task = null;
                contender.tasks.forEach(task => {
                    if(achievement.task.$oid == task._id.$oid) {
                        current_task = task
                    }
                });
                if (current_task == null) {
                    current_task = {}
                }
                return achievement.days.map((day, j) => {

                    const current_date = moment(day.day.$date).subtract(contender.profile.tz_offset, "minutes")
                    return day.notes.map((note, i) => {
                        tableData.push({
                            'username': current_username,
                            'title': current_task.title || "Unknown",
                            'date': moment(current_date).format('DD/MM/YYYY'),
                            'note': note,
                            'points': current_task.points || 1,
                            'bonus': achievement.bonus ? "TRUE" : "FALSE",
                            'note_index': i,
                            'day_index': j,
                            'achievement_index': k

                        })
                    })
                })

            });

        })


        return (
            <div className="Company">
                <p>{company.company}</p>

                <MaterialTable
                    title="Contender Points"
                    columns={columns}
                    data={tableData}
                    actions={[
                        {
                            icon: 'delete',
                            tooltip: 'Delete',
                            onClick: (event, rowData) => handleDelete(rowData)
                        }
                    ]} />
            </div>


        );
    } else if (fail) {
        return 'FAIL'
    } else {
        return 'loading...'
    }
}

export default Company;