import React, {Fragment} from 'react';
import {Redirect, NavLink} from 'react-router-dom'
import {TabMenu} from 'primereact/tabmenu';
import 'primereact/resources/themes/nova-light/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeicons/primeicons.css';
import '../Nav/nav.css';
const Nav = (props) => {
    
    const items = [
        {
          label: 'companies',
          icon: 'pi pi-fw pi-users',
          onTabChange: (e) => {
            return <Redirect to="challenges" />
          }

        },
        {
          label: 'challenges',
          icon: 'pi pi-fw pi-folder-open',

        }
      ]


      
      return (
        <nav>
          <ul>
            <li><NavLink to='/'><i className="pi pi-home" style={{fontSize: '2em'}}></i><span>Home</span></NavLink></li>
            <li><NavLink to='/challenges'><i className="pi pi-users" style={{fontSize: '2em'}}></i><span>Activities</span></NavLink></li>
            <li><NavLink to='/companies'><i className="pi pi-briefcase" style={{fontSize: '2em'}}></i><span>Companies</span></NavLink></li>
            <li><NavLink to='/categories'><i className="pi pi-paperclip" style={{fontSize: '2em'}}></i><span>Ways</span></NavLink></li>
            <li><NavLink to='/users'><i className="pi pi-user" style={{fontSize: '2em'}}></i><span>Users</span></NavLink></li>
          </ul>
        </nav>
      )

}

export default Nav;