import React, {useContext, useState, useEffect}  from 'react';

import { Route } from 'react-router-dom';
import Details from '../../components/Details/Details';
import Nav from '../../components/Nav/Nav';
import AnimatedLogo from '../../components/Overlay/AnimatedLogo/AnimatedLogo';
import superApi from '../../Api/Api';
import TitleBar from '../../components/TitleBar/TitleBar';
import Aux from '../../HOC/Aux/Aux';
import { AuthContext } from '../../Context/AuthContext';
import Activities from './Activities/Activities';
import Users from './Users/Users';
import ContenderDetails from '../../components/Details/Company/Contenders/ContenderDetails/ContenderDetails';

const Dashboard = () => {
    
    const moment = require('moment')
    const auth = useContext(AuthContext);

    const getTimeStamp = () => {
        let date = new Date();
        return date.getTime();
    }

    const addCategoryHandler = (e) => {
        e.preventDefault();
        let fieldArray = Object.keys(addCategoryForm.fields);
        let obj2 = {};
        fieldArray.map((field) => {
            obj2[field] = addCategoryForm.fields[field].value;
            return null;
        });
        
        superApi.post('/category', obj2)
        .then(
            (response) => {
                setShowNewCompany(false);
                superApi.get('/company')
                    .then(
                        (response) => {
                        // handle state change
                        setCompanies(response.data._items);
                    }
                    );
                setShowNewCategory(false);
                getCategories();
            }
        )
        .catch(
            (error) => console.log(error)
        )
 
    }

    const editChallengeHandler = (model) => {
        
        superApi.patch('/task/'+challenge._id, model)
        .then((response) => {
            setChallenge({});
            getChallenges();
        })
    }

    const addChallengeHandler = (model) => {
        superApi.post('/task/', model)
        .then((response) => {
            setChallenge({});
            getChallenges();
        })
    }



    const addCompanyHandler = (e) => {
        e.preventDefault();
        let fieldArray = Object.keys(addCompanyForm.fields);
        let data = {};
        fieldArray.map((field) => {
            if(field == 'startdate') {
                let _date = moment(addCompanyForm.fields[field].value);
                _date.set({millisecond:0})                
                data[field] = _date.toISOString()
            } else {

                data[field] = addCompanyForm.fields[field].value;
            }
            return null;
        });
        superApi.post('/company', 
            data)
        .then(
            (response) => {
                setShowNewCompany(false);
                setSelectedChallenges([]);
                getCompany(response.data._id);
                superApi.get('/company')
                    .then(
                        (response) => {
                        // handle state change
                        setCompanies(response.data._items);
                    }
                    );
            }
        )
        .catch(
            (error) => console.log(error)
        )
    }

    const addModeratorHandler = (e, company) => {
        e.preventDefault();
        setUpdated(false);
        let fieldArray = Object.keys(addModeratorForm.fields);
        let data = {};
        fieldArray.map((field) => {
            data[field] = addModeratorForm.fields[field].value;
            return null;
        });
        data.roles = ['moderator'];
        data.company = company._id;

        superApi.post('/account', 
            data)
        .then(
            (response) => {
                superApi.patch('/company/' + company._id,
                    {moderator: response.data._id},{
                        auth: { username: "wadmin", 
                        password: "wadmin"}
                }).then(
                    (response) => {
                        getCompany(company._id);
                        setUpdated(true);

                    }
                ).catch(
                    (error) => {
                        
                    }
                )
                
            }
        ).catch(
            (error) => {
                console.log(error.response.data._issues);
                setUpdated(false);
                
            }
        )
    }

    const updateFormHandler = (e,input, form, callback) => {
        let copiedForm = {
            ...eval(form)
        };
        const copiedFields = {
            ...copiedForm.fields
        };
        const copiedField = {
            ...copiedFields[input]
        }
        copiedField.value = e.target.value;

        
        copiedForm.fields[input] = copiedField;
        // setAddCategoryForm(copiedForm);
        eval(callback)(copiedForm);

    }
    const [updated, setUpdated] = useState(true);
    const [responseError, setResponseError] = useState(null);
    const [challenges, setChallenges] = useState([]);
    const [challenge, setChallenge] = useState({});
    const [showNewChallenge, setShowNewChallenge] = useState(false);
    const [numberOfChallenges, setNumberOfChallenges] = useState(0);

    const [users, setUsers] = useState(null);
    const [selectedUser, setSelectedUser] = useState(null);


    const [companies, setCompanies] = useState([]);
    const [company, setCompany] = useState({});
    const [mod, setMod] = useState(null);
    // const [newCompany, setNewCompany] = useState([]);
    const [showNewCompany, setShowNewCompany] = useState(false);
    const [numberOfCompanies, setNumberOfCompanies] = useState(0);

    const [categories, setCategories] = useState([]);
    const [category, setCategory] = useState({});
    const [showNewCategory, setShowNewCategory] = useState(false);
    const [numberOfCategories, setNumberOfCategories] = useState(0);
    const [selectedChallenges, setSelectedChallenges] = useState([]);
    const [searchValue, setSearchValue] = useState("");
    const [searchCategory, setSearchCategory] = useState("");
    const [contenders, setContenders] = useState([]);
    const [contender, setContender] = useState(null);

    const [addCategoryForm, setAddCategoryForm] = useState({
        fields: {
            name: {
                type: 'input',
                placeholder: 'Name',
                
                value: ''
            },
            slogan: {
                type: 'input',
                placeholder: 'Slogan',
                
                value: ''
            },
            color: {
                type: 'input',
                placeholder: '#000000',
                value: ''
            }
            
        },
        onChange: updateFormHandler,
        onSubmit: addCategoryHandler,
        updateValueCallback: 'setAddCategoryForm'
    });

    const [addCompanyForm, setAddCompanyForm] = useState({
        fields: {
            company: {
                type: 'input',
                placeholder: 'Company Name',
                
                value: ''
            },
            startdate: {
                type: 'calendar',
                placeholder: '',
                value: ''
            }
            
        },
        onChange: updateFormHandler,
        onSubmit: addCompanyHandler,
        updateValueCallback: 'setAddCompanyForm'
    });

    const [addModeratorForm, setAddModeratorForm] = useState({
        fields: {
            username: {
                type: 'input',
                placeholder: 'Username',
                
                value: ''
            },
            password: {
                type: 'password',
                placeholder: 'Password',
                
                value: ''
            }
            
            
        },
        onChange: updateFormHandler,
        onSubmit: addModeratorHandler,
        updateValueCallback: 'setAddModeratorForm'
    });

    const [addChallengeForm, setAddChallengeForm] = useState({
        fields: {
            title: {
                type: 'input',
                placeholder: 'Activity Title',
                value: ''
            },
            
            description: {
                type: 'input',
                placeholder: 'Activity Description',
                value: ''
            },
            category: {
                type: 'select',
                placeholder: 'Category',
                options: [
                    {name: 'Active'},
                    {name: 'Learn'},
                    {name: 'Connect'}, 
                    {name: 'Give'},
                    {name: 'Notice'}
                ],
                optionLabel: "name",
                value: ''
            },
            delay: {
                type: 'select',
                placeholder: 'Delay Days',
                options: [
                    {delay: 0},
                    {delay: 7},
                    {delay: 14},
                    {delay: 21},
                    {delay: 28},
                    {delay: 35}

                ],
                optionLabel: "delay",
                readonly: true,
                value: 0
            }
        },
        onChange: updateFormHandler,
        onSubmit: addChallengeHandler,
        updateValueCallback: 'setAddChallengeForm'
    });

    useEffect(() => {
        setNumberOfChallenges(challenges.length);
        setNumberOfCompanies(companies.length);
        setNumberOfCategories(categories.length);

   });

   useEffect(() => {
       getAccounts();
   },[])

    const getChallenges = () => {
        superApi.get('/task?'+getTimeStamp())
        .then(
            (response) => {
            // handle state change
            
            setChallenges(response.data._items);
        }
        );
    }

    const getTaskTitle = (task) => {
        superApi.get('/task/' + task)
        .then((response) => {
            
            
            return response.data.title;
        });
    }

    const getContenderAchievements = (_contender) => {
        superApi.get('/account/'+_contender._id)
        .then((response) => {
            setContender(response.data);
        })
    }

    const getContenderProfile = (contender) => {
        
        superApi.get('/contender/profile/?user=' +contender._id)
        .then((response) => {
            console.info(response.data)
            setContender(response.data)
        })
    
}

    const getUsers = () => {
        superApi.get('/account?'+getTimeStamp())
        .then(
            (response) => {
                setUsers(response.data._items);
            }
        );
    }

    const setSelectedUserHandler = (user) => {
        setSelectedUser(user);
    }
    const getCompanies = () => {

        superApi.get('/company?'+getTimeStamp())
        .then(
            (response) => {
            // handle state change
            setCompanies(response.data._items);
        }
        );
    }

    const getCompany = (id) => {
        superApi.get('/company/' + id +'/?'+getTimeStamp())
        .then(
            (response) => {
            // handle state change
            // console.log(response.data)
            setCompany(response.data);
        }
        );
    }

    const getAccounts = () => {
        superApi.get('/account')
        .then(
            (response) => {
                setContenders(response.data._items);
            }
        )
    }
    const getCategories = () => {

        superApi.get('/category?'+getTimeStamp())
        .then(
            (response) => {
            // handle state change
            setCategories(response.data._items);
            //update challenges form options (state)
            let copiedForm = {
                ...addChallengeForm
            }
            let copiedFields = {
                ...copiedForm.fields
            }
            let copiedField = {
                ...copiedFields.category
            }
            copiedField.options = [];
            response.data._items.map((category) => {
                copiedField.options.push({
                    name: category.name
                });
                return null;
            });
            copiedForm.fields['category'] = copiedField;
            setAddChallengeForm(copiedForm);
            
        }
        );
    }

    const showNewCompanyHandler = () => {

        if(showNewCompany) {
            setShowNewCompany(false);
        } else {
            setShowNewCompany(true);
        }  
        
    }
    const showNewCategoryHandler = () => {

        if(showNewCategory) {
            setShowNewCategory(false);
        } else {
            setShowNewCategory(true);
        }  
        
    }




    const setSelectedChallengesHandler = (challenge) => {

        const patchCompanyChallenges = (_challenges) => {
            let challengeIds = _challenges.map((challenge) => {
                return challenge._id
            });
            superApi.patch('/company/' + company._id,
                {tasks: challengeIds}
            ).then(
                (response) => {
                   
                    getCompany(company._id);
                    return setUpdated(true);
                }
            )
        }
        setUpdated(false);
        let prevSelected = [];
        selectedChallenges.map((challenge) => {
            prevSelected.push(challenge);
            return null;
        })

        for(let i in prevSelected) {
            if(prevSelected[i].title === challenge.title) {
                prevSelected.splice(i,1);
                setSelectedChallenges(prevSelected);
                patchCompanyChallenges(prevSelected);
                return setUpdated(true);
   
            }
            
        }
        
        prevSelected.push(challenge);
        setSelectedChallenges(prevSelected);
        patchCompanyChallenges(prevSelected);

    }
    const showNewChallengeHandler = () => {

        if(showNewChallenge) {
            setShowNewChallenge(false);
        } else {
            setShowNewChallenge(true);
        }  
        
    }

    const showCategoryDetailHandler = (e, category) => {
        superApi.get('/category/' + e.value)
        .then(
            (response) => {
                setCategory(response.data);
        }
        );
    }

    const showCompanyDetailHandler = (event, company) => {
        superApi.get('/company/' + event.value + '?' + getTimeStamp())
        .then(
            (response) => {
                setSelectedChallenges([]);
                setCompany(response.data);
                    let _challenges = []
                    response.data.tasks.map((challenge) => {
                        
                        superApi.get('/task/' + challenge)
                        .then(
                           
                            (response) => {
                                _challenges.push(response.data);
                                setSelectedChallenges(_challenges);
                                setMod(null);
                                
                            }
    
                            
                        )
                        return null;
                        
                    });
                    
                
                
                //
                
        }
        )
    }

    const showChallengeDetailHandler = (activity) => {
        superApi.get('/task/' + activity)
        .then(
            (response) => {
                // console.log(response);
                setChallenge(response.data);
        }
        );
    }

    const deleteChallengeHandler = (id) => {
        if(window.confirm('Are you sure?')) {
                superApi.delete('/task/'+id
                )
                .then(
                    () => {
                        getChallenges();
                        setChallenge({});
                    }
                )
            }
        }

    const deleteCompanyHandler = (id) => {
        if(window.confirm('Are you sure?')) {
                superApi.delete('/company/'+id
                )
                .then(
                    () => {
                        getCompanies();
                        setCompany({});
                    }
                )
            }
        }

    const deleteCategoryHandler = (id) => {
        if(window.confirm('Are you sure?')) {
            superApi.delete('/category/'+id
                )
                .then(
                    () => {
                        getCategories();
                        setCategory({});
                    }
                )
            }   
        }
        
    const resetContenderHandler = () => {
        setContender(null);
    }

        const CATEGORYBUTTONS = categories.map((category) => {
            return <a className="filterButton" label={category.name} style={{color: category.color, borderColor: category.color}} onClick={() => setSearchCategory(category.name)}>{category.name}</a>
        })
        CATEGORYBUTTONS.push(<a className="filterButton" label="CLEAR FILTER" onClick={() => setSearchCategory("")}>CLEAR FILTER</a>)
       

    
    
    const categoryActionProps = {
        addNew: showNewCategoryHandler, //show modal
        items: categories, //items for select
        placeholder: 'Select Category', //placeholder for select
        showItem: showCategoryDetailHandler,//onchange show item
        type: 'categories', //for switch case to populate select ['companies', 'challenges', 'categories']
    }
    const categoryModalProps = {
        header: 'New Category',
        showDialog: showNewCategory,
        onHideHandler: showNewCategoryHandler
    }

    const companyActionProps = {
        addNew: showNewCompanyHandler, //show modal
        items: companies, //items for select
        placeholder: 'Select Company', //placeholder for select
        showItem: showCompanyDetailHandler,//onchange show item
        type: 'companies', //for switch case to populate select ['companies', 'challenges', 'categories']
    }
    const companyModalProps = {
        header: 'New Company',
        showDialog: showNewCompany,
        onHideHandler: showNewCompanyHandler   
    }

    const challengeActionProps = {
        addNew: showNewChallengeHandler, //show modal
        items: challenges, //items for select
        placeholder: 'Select Activity', //placeholder for select
        showItem: showChallengeDetailHandler,//onchange show item
        type: 'challenges', //for switch case to populate select ['companies', 'challenges', 'categories']
    }
    const challengeModalProps = {
        header: 'New Challenge',
        showDialog: showNewChallenge,
        onHideHandler: showNewChallengeHandler
    }

    return (
        <Aux authenticated={auth.authenticated} setAuthenticated={auth.setAuthenticated}>
            <TitleBar title="Wellbeing Admin" />
            <Route path="/" exact component={AnimatedLogo} />
            <Route path="/users" render={
                () => <Users route="users"
                        getItems={getUsers}
                        setItem={setSelectedUserHandler}
                        items={users}
                        item={selectedUser} />
            } />
            <Route path="/challenges" render={
                
                () => <Activities 
                route="challenges"
                actions={challengeActionProps}
                modal={challengeModalProps}
                getItems={getChallenges}
                items={challenges}
                form={addChallengeForm}
                item={challenge}
                deleteObject={deleteChallengeHandler}
                editObject={editChallengeHandler}
                addObject={addChallengeHandler}
                clearCurrent={() => setChallenge({})}
                categories={categories} 
                getCategories={getCategories}
                searchCategory={searchCategory}
                setSearchCategory={setSearchCategory}
                searchValue={searchValue}
                setSearchValue={setSearchValue}
                CATEGORYBUTTONS={CATEGORYBUTTONS}
                /> } />

            <Route path="/companies" render={
                () => <Details
                    route="companies" 
                    actions={companyActionProps}
                    modal={companyModalProps}
                    getItems={getCompanies}
                    items={companies}
                    form={addCompanyForm}
                    modForm={addModeratorForm}
                    item={{data: {...company}, headerKey:'company'}}
                    deleteObject={deleteCompanyHandler}
                    allChallenges={challenges}
                    getChallenges={getChallenges}
                    setChallenges={setChallenges}
                    updateSelectedChallenges={setSelectedChallengesHandler}
                    // updateCompanyChallenges={setSelectedChallengesHandler}
                    selectedChallenges={selectedChallenges}
                    updated={updated}
                    mod={mod}
                    setMod={setMod}
                    responseError={responseError}
                    searchCategory={searchCategory}
                    setSearchCategory={setSearchCategory}
                    searchValue={searchValue}
                    setSearchValue={setSearchValue}
                    CATEGORYBUTTONS={CATEGORYBUTTONS}
                    contenders={contenders}
                    contender={contender}
                    getContenderProfile={getContenderProfile}
                    resetContenderHandler={resetContenderHandler}
                    
                        />} />
            <Route path="/categories" render={
                () => <Details 
                    route="categories"
                    actions={categoryActionProps}
                    modal={categoryModalProps}
                    getItems={getCategories}
                    items={categories}
                    form={addCategoryForm}
                    item={{data: {...category}, headerKey:'name'}}
                    deleteObject={deleteCategoryHandler}
                    
                />
            } />

            {/* <Route path="/contender/:contender" render={() => <ContenderDetails contender={contender} />} /> */}
            <Nav />
        </Aux>
    );
}

export default Dashboard;