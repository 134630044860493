import React, {useEffect, useState} from 'react';
import {Toolbar} from 'primereact/toolbar';
import {Button} from 'primereact/button';
import {Dropdown} from 'primereact/dropdown';
import './ActionBar.css';
const ActionBar = (props) => {
    // const [selectItems, setSelectItems] = useState([]); 
    let selectItems = [];
    
        switch (props.type) {
            case 'challenges':
                
                props.items.map((item) => {
                    selectItems.push(
                        {   
                            key: item._id,
                            label: item.title,
                            value: item._id
                        }
                    )
        
                    return null;
                });
                break;
                case 'companies':
                if (props.items.length > 0) {
                    selectItems = []
                    props.items.map((item) => {
                        selectItems.push(
                            {
                                key: item._id,
                                label: item.company,
                                value: item._id
                            }
                        )
                        return null;
                    });
                }
                
                break;
                case 'categories':
        
                props.items.map((item) => {
                    selectItems.push(
                        {
                            key: item._id,
                            label: item.name,
                            value: item._id
                        }
                    )
                    return null;
                });
                
                break;
        
                default:
                break;
            }

            

    
    if(selectItems.length >= 0) {
        return (
            <React.Fragment>
            <Toolbar className='ActionBar' >
                <div className="">
                    <Button onClick={props.addNew} label="New" icon="pi pi-plus" style={{marginRight:'.25em'}} />
                    <Dropdown options={selectItems} placeholder={props.placeholder} onChange={(e) => props.showItem(e, e.target.value)}/>
                    
                </div>
            </Toolbar>
            
            </React.Fragment>
        )
    } else {
        return <p>Loading</p>;
    }
    
}

export default ActionBar;