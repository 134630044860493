import React,{useEffect,useState} from 'react';
import './Users.css';
import User from './User/User';
import UserDetail from './UserDetail/UserDetail';
import {Dialog} from 'primereact/dialog';

const Users = (props) => {


    useEffect(() => props.getItems(),[])

    const [showUserDetails, setShowUserDetails] = useState(false);

    const showUserDetailHandler = (user) => {
        props.setItem(user);
        setShowUserDetails(true);
    }

    let USERS = [];
    if(props.items) {
        USERS = props.items.map((user) => {
            if(!user.roles.includes('super')) {
                return <User key={user._id} 
                                user={user}
                                showUser={showUserDetailHandler} />
            }
            
        })
    }

    
    
    return ( 
        <div className="Users">
        <ul>
            {USERS}
        </ul>
       <Dialog header="User Details"
                visible={showUserDetails}
                modal={true}
                onHide={() => setShowUserDetails(false)}>
                    <UserDetail user={props.item} />
        </Dialog> 
        </div>
     );
}
 
export default Users;