import React, { useEffect, useState } from 'react';
import './Contenders.css';
import Contender from './Contender/Contender';
import ContenderDetails from './ContenderDetails/ContenderDetails';
import { Card } from 'primereact/card';
import superApi from '../../../../Api/Api';

const Contenders = (props) => {

    let teams = {};
    let _teamMap = {}
    const [teamMap, setTeamMap] = useState({})
    const [teamsLoaded, setTeamsLoaded] = useState(false)
    teams.loners = [];

    useEffect(() => {
        superApi.get('/team').then(response => {
            
            response.data._items.map(team => _teamMap[team._id] = team.name)
            setTeamMap(_teamMap)
            setTeamsLoaded(true)
        })
    },[])
    
    props.contenders.map(contender => {
        if (contender.company === props.company) {
            if (contender.profile != null && contender.profile.team != null) {
                if (teams[teamMap[contender.profile.team]] == null) {
                    teams[teamMap[contender.profile.team]] = [];

                }
                teams[teamMap[contender.profile.team]].push(contender);


            } else {
                teams.loners.push(contender);
            }
        }
        return null;
    });

    const TEAMLIST = Object.entries(teams).map((team, i) => {
        const header = team[0];
        const members = team[1].map(contender => {
            return <Contender key={contender._id} contender={contender} click={props.getContenderProfile} />
        });
        return (
            <React.Fragment>
                <div className="p-col-12 p-md-6 p-nogutter Contenders">
                    <Card>
                        <h3>{header}</h3>
                        <ul>
                            {members}
                        </ul>

                    </Card>
                </div>
            </React.Fragment>
        )
    });


    return (
        <React.Fragment>

            {teamsLoaded ? TEAMLIST : null}

        </React.Fragment>
    );
}

export default Contenders;