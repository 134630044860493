import React,{useEffect} from 'react';
// import { Redirect } from 'react-router';
import {Dialog} from 'primereact/dialog';

const Aux = (props) => {
    useEffect(() => {
        if(window.localStorage.getItem('expired')) {
            props.setAuthenticated(false);
        }
    })

    if(props.authenticated) {
        return props.children;
    } else {
        return <a href="/login">Login Required</a>
    }
    
}
export default Aux;