import React from 'react';
import {Panel} from 'primereact/panel';
import './Category.css';
import {Button} from 'primereact/button';



const Category = (props) => {

    const footer = 
    <span>
        <Button label="Delete" icon="pi pi-times" className="p-button-danger" onClick={() => props.delete(props.item._id)}/>
    </span>

    return (
            
                <div className="p-col-12">
                    <Panel header="Category" collapsible={false} >
                        <h1>{props.item.name}</h1>
                        {footer}
                    </Panel>
            
                </div>
            
        
    )
}

export default Category;