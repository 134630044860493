import React, {Fragment,useEffect} from 'react';
import ActionBar from '../../containers/Dashboard/ActionBar/ActionBar';
import Modal from '../Overlay/Modal/Modal';
import Form from '../Form/Form';
import {Panel} from 'primereact/panel';
import Company from './Company/Company';
import Challenge from './Challenge/Challenge';
import Category from './Category/Category';
import ListItem from '../Details/ListItem/ListItem';
import AnimatedLogo from '../Overlay/AnimatedLogo/AnimatedLogo';
const Details = (props) => {
    useEffect(() => {
        props.getItems();
    },[])
    let PAGE = null;
    const panelStyle = {
        margin: '0'
    }

    const LISTITEMS = props.items.map((item) => {
        return (<ListItem key={item._id} item={item} />)
        

    });

    
    if (props.item.data) {
        switch(props.route) {
            case ("challenges"):
            if(Object.entries(props.item.data).length === 0 ) {
                PAGE = <AnimatedLogo />;
            } else {
                PAGE = (
                    <Challenge item={props.item.data} delete={props.deleteObject} />
                
                )
            }
            break;
            case 'companies':
            if(Object.entries(props.item.data).length === 0 ) {
                PAGE = <div />;
            } else {
                PAGE = <Company 
                            {...props} 
                            challenges={props.allChallenges} 
                            getChallenges={props.getChallenges}
                            updated={props.updated} 
                            delete={props.deleteObject}
                            setSearchCategory={props.setSearchCategory}
                            searchValue={props.searchValue}
                            setSearchValue={props.setSearchValue}
                            CATEGORYBUTTONS={props.CATEGORYBUTTONS}
                            contenders={props.contenders}
                            contender={props.contender}
                            getContenderAchievements={props.getContenderAchievements}
                            // addChallengeToCompany={props.addChallengeToCompany} 
                            // removeChallengeFromCompany={props.removeChallengeFromCompany}
                            />
            }
            
            break;
            case 'categories':
            if(Object.entries(props.item.data).length === 0 ) {
                PAGE = <div></div>;
            } else {
            PAGE = (
                <Category item={props.item.data} delete={props.deleteObject}/>
            )
            }
            break;
            default:
                PAGE = (
                    <div>Does not exist!</div>
                )
                
        }
    }
    

    return (
        <Fragment>
        <ActionBar 
            addNew={props.actions.addNew}
            items={props.items}
            item={props.item}
            placeholder={props.actions.placeholder}
            showItem={props.actions.showItem}
            type={props.actions.type}/>
        <Modal 
                    header={props.modal.header} 
                    showDialog={props.modal.showDialog} 
                    onHideHandler={props.modal.onHideHandler}
                    >
            <Form
                form={props.form}>

            </Form>
                
        </Modal>
        
        {PAGE}
        </Fragment>
    );
}

export default Details;