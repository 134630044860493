import React from 'react';
import {Card} from 'primereact/card';
import './Challenge.css';
import {Button} from 'primereact/button';
import {Panel} from 'primereact/panel';
const Challenge = (props) => {

    const footer = 
    <span>
        <Button label="Delete" icon="pi pi-times" className="p-button-danger" onClick={() => props.delete(props.item._id)}/>
    </span>

    

    return (
            
                <div className="p-col-12">
                    <Panel header="Activity" toggleable={false} collapsed={false}>
                        <h1>{props.item.category}</h1>
                        <p>{props.item.title}</p>
                        {footer}
                    </Panel>
            
                </div>
            
        
    )
}

export default Challenge;