import React, { useEffect, useState } from 'react';

export const AuthContext = React.createContext();

export default (props) => {
    // const prevAuth = (!window.localStorage.getItem('authenticated') || window.localStorage.getItem('authenticated') === 'false') ? false : true;
    const prevAuth = window.localStorage.getItem('auth') || false; 
    const prevAuthBody = window.localStorage.getItem('authBody') || null; 
    const [authenticated, setAuthenticated] = useState(prevAuth);
    const [authBody, setAuthBody] = useState(prevAuthBody);

    const logoutHandler = (e) => {
        e.preventDefault();
        setAuthBody(null);
        setAuthenticated(false);
        window.localStorage.removeItem('auth');
        window.localStorage.removeItem('authBody');
    }



    useEffect(() => {
        
        if(window.localStorage.getItem('expired')){
            setAuthenticated(false);
            window.localStorage.removeItem('expired');
            
        }

        
        (authenticated) ? window.localStorage.setItem('auth', authenticated) : window.localStorage.removeItem('auth');
        window.localStorage.setItem('authBody', authBody);
    },[authenticated, authBody]);



    const defaultContext = {
        authenticated,
        setAuthenticated,
        authBody,
        setAuthBody,
        logoutHandler,

    };

    return (
        <AuthContext.Provider value={defaultContext}>
            {props.children}
        </AuthContext.Provider>
    );
}