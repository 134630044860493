import React, { useEffect, useRef, useState } from 'react';
import { Panel } from 'primereact/panel';
import Challenge from './Challenge/Challenge';
import Moderator from './Moderator/Moderator';
import { Growl } from 'primereact/growl';
import Form from '../../Form/Form';
import './Company.css';
import { Card } from 'primereact/card';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';
import { Dialog } from 'primereact/dialog';
import Contenders from './Contenders/Contenders';
import ContenderDetails from './Contenders/ContenderDetails/ContenderDetails';
import { Route, Redirect } from 'react-router-dom';

const Company = (props) => {
    // let growlRef = useRef("bogie");
    // let MOD = null;

    const sortTasks = () => {
        props.challenges.sort((a, b) => {
            if (a.category > b.category) {
                return 1;
            } else {
                return -1;
            }
        });
    }


    const renderTasks = () => {
        // sortTasks();
        let ALLTASKS = props.challenges.map((challenge) => {

            if (props.searchCategory === "") {
                if ((challenge.title.toLowerCase().includes(props.searchValue.toLowerCase()) || challenge.description.toLowerCase().includes(props.searchValue.toLocaleLowerCase()))) {
                    if (props.item.data.tasks.includes(challenge._id)) {
                        return (<Challenge
                            key={challenge._id}
                            challenge={challenge}
                            buttonLabel="Remove"
                            buttonIcon="pi pi-check"
                            buttonClick={props.updateSelectedChallenges}
                            buttonClass="pi pi-check"
                        />)
                    } return (<Challenge
                        key={challenge._id}
                        challenge={challenge}
                        buttonLabel="Add"
                        buttonIcon="pi pi-times"
                        buttonClick={props.updateSelectedChallenges}
                        buttonClass="pi pi-times"
                    />)
                }
            }
            if (props.searchCategory === challenge.category) {
                if ((challenge.title.toLowerCase().includes(props.searchValue.toLowerCase()) || challenge.description.toLowerCase().includes(props.searchValue.toLocaleLowerCase()))) {
                    if (props.item.data.tasks.includes(challenge._id)) {
                        return (<Challenge
                            key={challenge._id}
                            challenge={challenge}
                            buttonLabel="Remove"
                            buttonIcon="pi pi-check"
                            buttonClick={props.updateSelectedChallenges}
                            buttonClass="pi pi-check"
                        />)
                    } return (<Challenge
                        key={challenge._id}
                        challenge={challenge}
                        buttonLabel="Add"
                        buttonIcon="pi pi-times"
                        buttonClick={props.updateSelectedChallenges}
                        buttonClass="pi pi-times"
                    />)
                }
            }




        })


        return ALLTASKS
    }

    useEffect(() => {
        props.getChallenges();

        // growlRef.current.show({severity: "success", summary: "Loaded", detail: "Challenges await"})
    }, []);






    // setSelected(renderTasks());
    const panelStyle = {
        margin: '20px',
        marginBottom: '60px'
    }

    const CONTENDERS = <Contenders contenders={props.contenders} getContenderProfile={props.getContenderProfile} company={props.item.data._id} />


    let startDate = new Date(props.item.data.startdate || null);
    if (startDate) { startDate = startDate.toLocaleString(); }

    return (
        <React.Fragment>

            <div className="Company">
                <div className="company-title" >
                    {props.item.data[props.item.headerKey]}
                </div>

                <div className="p-grid">
                    <div className="p-col-12">

                        <div style={{ fontSize: '2em', textAlign: 'center', padding: '10px', backgroundColor: 'yellow' }}>
                            <span>SLUG - <a target="_blank" href={"https://app.thewellbeingchallenge.co.nz/register/" + props.item.data.slug} style={{ marginLeft: '10px' }}>{props.item.data.slug}</a></span>

                        </div>
                        {CONTENDERS}
                        <div className="search">
                            <InputText placeholder="Type Something" value={props.searchValue} onChange={(e) => props.setSearchValue(e.target.value)} />
                            <span className="categoryButtons p-grid">{props.CATEGORYBUTTONS}</span>
                        </div>
                        <ul style={{ listStyle: 'none', padding: '20px' }}>
                            {(props.item.data.company !== 'fake') ? renderTasks() : null}
                        </ul>
                    </div>


                    {/* {props.contender ? <Redirect to={'/contender/'+props.contender._id} contender={props.contender} /> : null} */}

                    {/* <Button label="Delete Company" icon="pi pi-times" className="p-button-danger" onClick={() => props.delete(props.item.data._id)}/> */}



                    {/* <div className="p-col-12 p-md-6">
                    {MOD}
                </div> */}
                </div>
                <ContenderDetails back={props.resetContenderHandler} contender={props.contender} />
            </div>
        </React.Fragment>
    )

}

export default Company;