import React from 'react';
import './ListItem.css';
const ListItem = (props) => {
    return (
        <div class="list-item">
            <p><strong>{props.item.title}</strong><br />
                {props.item.description}</p>
        </div>
    )
}

export default ListItem;