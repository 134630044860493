import React, { useState, useEffect } from 'react';
import './ContenderDetails.css';
import { Card } from 'primereact/card';
import superApi from '../../../../../Api/Api';
import { Redirect } from 'react-router-dom';

let moment = require('moment');


const ContenderDetails = (props) => {
    // let ACTIVITIES = []
    if (props.contender) {



        let ACTIVITIES = []
        props.contender.achievements.map(achievement => {
            const task = achievement.task
            let NOTES = []
            achievement.days.map(day => {
                NOTES = []
                const _day = moment(day.day).format('D MMM YY')
                const points = day.points
                const _notes = day.notes.map(note => {
                    NOTES.push(<tr className="note"><td>{note}</td></tr>)
                })
                ACTIVITIES.push(<React.Fragment><tr className="day"><td>{_day} <span className="points">POINTS: {points}</span></td></tr><tr className="task"><td>{task}</td></tr>{NOTES}</React.Fragment>)
            })

        })


        window.scrollTo(0, 0)


        const header = <img alt="Card" src={superApi.defaults.baseURL + "avatar/" + props.contender.avatar} />
        return (
            <div className="ContenderDetails">
                <div className="titlebar">
                    <a onClick={props.back}>
                        <i class="material-icons">
                            arrow_back
                        </i>
                    </a>
                </div>
                <div className="p-grid p-justify-around">
                    <div className="p-md-4">
                        <Card className="id-card" title="Contender"
                            header={header}>

                            <p>{props.contender.email}</p>
                            <p>{props.contender.name || ""}</p>
                            <p>{props.contender.company}</p>

                        </Card>
                    </div>
                    <div className="p-md-8">
                        <Card className="points-card" title="Points">



                        </Card>
                    </div>
                </div>
                <div class="p-grid p-justify-around">
                    <div class="p-col">
                        <Card className="history-card" title="History">
                            <table>
                                <tbody>
                                    {ACTIVITIES}
                                </tbody>
                            </table>
                        </Card>
                    </div>
                </div>
            </div>
        );
    }
    else {
        return null;
    }
}

export default ContenderDetails;