import React from 'react';
import {Button} from 'primereact/button';
import './Challenge.css';
const Challenge = (props) => {
   
    const _classes = "Activity "+props.challenge.type+" "+props.challenge.category;

    let _icon = <i class="pi pi-check"></i>;
    switch (props.challenge.category) {
        case 'active':
            _icon = (<i className="pi pi-images"></i>)

            break;
            case 'notice':
            _icon = (<i className="pi pi-inbox"></i>)

            break;
            case 'learn':
            _icon = (<i className="pi pi-table"></i>)

            break;
            case 'give':
            _icon = (<i className="pi pi-paperclip"></i>)

            break;
            case 'connect':
            _icon = (<i className="pi pi-list"></i>)

            break;
                
    }

    return (


        <li className="company-challenge">

            <span>{_icon}&nbsp;&nbsp;&nbsp;{props.challenge.title}{props.challenge.type == 'challenge' ? <i style={{color: 'orange'}} className="pi pi-star"></i> : ""}</span>
            <button 
                    style={{border: 'none', background: 'none', color: '#5CC2A7'}}
                    onClick={(e) => props.buttonClick(props.challenge)}>
                    <i className={props.buttonClass} style={{fontSize: '2em'}} ></i>
            </button>
            
        </li>
    )

}

export default Challenge;