import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './Companies.css';
import superApi from '../../../Api/Api';

const Companies = (props) => {

    useEffect(() => {
        superApi.get('company').then(response => {
            console.log(response.data)
            setCompanies(response.data['_items'])
        })
    },[])

    const [companies, setCompanies] = useState([])

    const COMPANY_LIST = companies.map(company => {
        return (
            <li className="company">
                <Link to={props.match.url + '/' + company._id}>{company.company}</Link>
            </li>
        )
    })
    return (
        <ul>
            {COMPANY_LIST}
        </ul>
    );
}

export default Companies;