import React from 'react';
import './Activity.css';

const Activity = (props) => {

    const _classes = "Activity "+props.activity.type+" "+props.activity.category;

    let _icon = <i class="pi pi-check"></i>;
    switch (props.activity.category) {
        case 'active':
            _icon = (<i className="pi pi-images"></i>)

            break;
            case 'notice':
            _icon = (<i className="pi pi-inbox"></i>)

            break;
            case 'learn':
            _icon = (<i className="pi pi-table"></i>)

            break;
            case 'give':
            _icon = (<i className="pi pi-paperclip"></i>)

            break;
            case 'connect':
            _icon = (<i className="pi pi-list"></i>)

            break;
                
    }
    return ( 
        <React.Fragment>
            
            <li className={_classes}>
                <div className="title-wrapper">
                <span className="category-icon">{_icon}</span>
                <span>&nbsp;{props.activity.title}{props.activity.type == 'challenge' ? <i style={{color: 'orange'}} className="pi pi-star"></i> : ""}</span>
                </div>
                <div className="actions-wrapper">
                    <a onClick={(e) => props.edit(props.activity._id)}>EDIT</a>&nbsp; 
                    &nbsp;<a onClick={() => props.delete(props.activity._id)}>DELETE</a>
                </div>
            </li>
        </React.Fragment>
     );
}
 
export default Activity;