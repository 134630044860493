import React, { useState, useContext } from 'react';
import './App.css';
import Dashboard from './containers/Dashboard/Dashboard';
import 'primereact/resources/themes/nova-colored/theme.css';
import 'primereact/resources/primereact.min.css';
import 'primeflex/primeflex.css';
import 'primeicons/primeicons.css';
import { Route, Switch } from 'react-router-dom';
import {BrowserRouter} from 'react-router-dom';
import AuthContext from './Context/AuthContext';
import superApi from './Api/Api';
import Login from './Auth/Login/Login';
import Layout from './containers/Layout/Layout';
import Aux from './HOC/Aux/Aux';
const App = () => {
  const [userValue, setUserValue] = useState('');
  const [passValue, setPassValue] = useState('');
  const [canSubmit, setCanSubmit] = useState(false);
  
  const setUserValueHandler = (val) => {
        
    setUserValue(val);
};

const setPassValueHandler = (val) => {
        
  setPassValue(val);
};
const disableButton = () => {
    setCanSubmit(false);
};
const enableButton = () => {
    setCanSubmit(true);
};

const registerSubmit = (model, auth) => {
    superApi.post('/register', model)
    .then((response) => {
        // receives token
        auth.setAuthenticated(true);
        auth.setAuthBody(response.data.token);

    })
    .catch((error) => {
        alert('Error');
    })
};

  const authForm = {
    userValue: userValue,
    passValue: passValue,
    canSubmit: canSubmit,
    setUserValueHandler: setUserValueHandler,
    setPassValueHandler: setPassValueHandler,
    disableButton: disableButton,
    enableButton: enableButton,
    registerSubmit: registerSubmit
  }
  

  
    return (
      
      <BrowserRouter>
      <AuthContext>

        <Switch>
        <Route path="/login" exact render={(props) => <Login {...props} auth={authForm}/>}/>
        <Route path="/new" render={(props) => <Layout {...props} />} />
        <Route path="/" render={() => <Dashboard />} />   
        
        </Switch>
        </AuthContext>
      </BrowserRouter>
    );
  }


export default App;
