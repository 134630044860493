import React, {useContext} from 'react';
import './TitleBar.css';
import {AuthContext} from '../../Context/AuthContext';
import {Button} from 'primereact/button';
import { Redirect } from 'react-router-dom';
 
const TitleBar = (props) => {
    const auth = useContext(AuthContext);
        return (
            <div className="TitleBar">
                {auth.authenticated ? <Button className="p-button-secondary" label="Logout" onClick={(e) => auth.logoutHandler(e)} /> : <Button className="p-button-secondary" label="Login" onClick={(e) => <Redirect to="/login" />} /> }
                {/* {auth.authenticated ? <a href="#" onClick={(e) => auth.logoutHandler(e)}>Logout</a> : ""} */}
                <span>{props.title.company || props.title}</span>
            </div>
        )
    
    
}

export default TitleBar;