import React, {useContext} from 'react';
import { Redirect } from 'react-router-dom';
import './Login.css';
import Formsy from 'formsy-react';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import FormsyElement from '../../HOC/FormsyElement/FormsyElement';
import {Password} from 'primereact/password';
import TitleBar from '../../components/TitleBar/TitleBar';
import superApi from '../../Api/Api';
import {AuthContext} from '../../Context/AuthContext';

const Login = (props) => {
    const auth = useContext(AuthContext);

    const loginSubmit = (model) => {
        superApi.post('/super', {}, {auth: {username: model.username, password: model.password}})
        .then((response) => {
          auth.setAuthBody(response.data.token)  
          auth.setAuthenticated(true);

        })
        .catch((error) => {
          // Login Failed
          window.localStorage.setItem('authenticated', false);
          window.localStorage.setItem('authBody', null);
          auth.setAuthenticated(false);
        });
 
      }

    if(!auth.authenticated) {  
    return ( 
        <React.Fragment>
            <TitleBar title={"Wellbeing Admin"} />
            <div className="Login">
            <Formsy onValidSubmit={(model) => loginSubmit(model)} 
                    onValid={() => props.auth.enableButton()}
                    onInvalid={() => props.auth.disableButton()}>

                <FormsyElement 
                    name="username"
                    value={props.auth.userValue}
                    // validations={}
                    // validationError="Please enter a valid Email"
                    required>
                    <InputText
                        value={props.auth.userValue}     
                        onChange={(e) => props.auth.setUserValueHandler(e.target.value)}
                        placeholder="username"
                    />
                </FormsyElement>

                <FormsyElement 
                    name="password"
                    value={props.auth.passValue}
                    // validations={}
                    validationError="Password too short"
                    required>
                    <Password
                        // name="username"
                        value={props.auth.passValue}     
                        onChange={(e) => props.auth.setPassValueHandler(e.target.value)}
                        placeholder="password"
                        feedback={false}
                    />
                </FormsyElement>
                   
                    <Button label="Submit" type="submit" disabled={!props.auth.canSubmit} />
            </Formsy>
            </div>
        </React.Fragment>
     );
    } else return <Redirect to="/" />
}
 
export default Login;