import React, { useContext } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import './Layout.css';
import Companies from './Companies/Companies';
import Company from './Companies/Company/Company';
import { AuthContext } from '../../Context/AuthContext';
import Aux from '../../HOC/Aux/Aux';

const Layout = (props) => {
    const auth = useContext(AuthContext)
    
    const prefix = '/new'

    return (
        <Aux authenticated={auth.authenticated} setAuthenticated={auth.setAuthenticated}>
        <BrowserRouter>
            <Switch>
                <Route exact path={prefix + '/company'} render={(props) => <Companies {...props} />} />
                <Route path={prefix + '/company/:id'} render={(props) => <Company {...props} />} />
            </Switch>
        </BrowserRouter>
        </Aux>
    );
}

export default Layout;