import React, {} from 'react';
import {InputText} from 'primereact/inputtext';
import {Dropdown} from 'primereact/dropdown';
import {Password} from 'primereact/password';
import {Button} from 'primereact/button';
import {Calendar} from 'primereact/calendar';

const Form = (props) => {
    const fields2Array = []
    for (let key in props.form.fields) {
        fields2Array.push({
            id: key,
            config: props.form.fields[key]
        })
    }
    

    let FIELDS2 = fields2Array.map((field) => {
        switch(field.config.type) {
            case 'input':
            return (
            
                <InputText value={field.config.value}
                            placeholder={field.config.placeholder}
                            key={field.id}
                            style={{display: 'block',
                                    marginBottom: '5px'}}
                            onChange={(e) => props.form.onChange(e, field.id, props.form, props.form.updateValueCallback)} />
            )
          
            case 'select':
            return (
                <Dropdown value={field.config.value}
                        key={field.id}
                        optionLabel={field.config.optionLabel}
                        options={field.config.options}
                        placeholder={field.config.placeholder}
                        disabled={field.config.readonly}
                        onChange={(e) => props.form.onChange(e, field.id, props.form, props.form.updateValueCallback)} />
            )
            case 'password':
           
            return (
                <Password value={field.config.value}
                        key={field.id}
                        placeholder={field.config.placeholder}
                        style={{display: 'block',
                                    marginBottom: '5px'}}
                        onChange={(e) => props.form.onChange(e, field.id, props.form, props.form.updateValueCallback)} />
            )
            
            case 'calendar':
            return (
                <Calendar 
                    dateFormat="dd/mm/yy"
                    showTime={true} 
                    hourFormat="24"
                    value={field.config.value}
                    readOnlyInput={true}
                    touchUI={false}
                    
                    
                    onChange={(e) => props.form.onChange(e, field.id, props.form, props.form.updateValueCallback)} />
            )
            default:
            return null;
        }
        
    })

    
    

    return (
        <form onSubmit={(e) => props.form.onSubmit(e, props.reference)} style={{textAlign: 'center'}}>
        <span style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
        }}>
        {FIELDS2}
                   
        </span>
        <Button 
            label="Save" 
            iconPos="right" 
            style={{marginTop: "10px"}} 
            icon="pi pi-check" 
            // onClick={(e) => props.Handler(e)}
            >
        </Button>    
        </form>
        )
}

export default Form;