import React from 'react';
import {Dialog} from 'primereact/dialog';

const Modal = (props) => {
    return (
        <Dialog header={props.header} visible={props.showDialog} onHide={props.onHideHandler}>
            {props.children}
        </Dialog>
    )
}

export default Modal;