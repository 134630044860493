import React, { useEffect, useState } from 'react';
import './Activities.css';
import Activity from './Activity/Activity';
import Formsy from 'formsy-react';
import {Dialog} from 'primereact/dialog';
import {Button} from 'primereact/button';
import FormsyElement from '../../../HOC/FormsyElement/FormsyElement';
import {InputText} from 'primereact/inputtext';
import {ToggleButton} from 'primereact/togglebutton';
import {Dropdown} from 'primereact/dropdown';
import {Spinner} from 'primereact/spinner';

const Activities = (props) => {
    
    useEffect(() => {
        props.getItems();
        props.getCategories();
        
    },[])

    useEffect(() => {
        let options = [];
         options = props.categories.map((cat) => {
            return {name: cat.name};
            
        })
        console.log(options)
        setFormCategoryOptions(options);
    }, [props.categories])

    useEffect(() => {
        if(Object.entries(props.item).length > 0) {
            setFormDescriptionValue(props.item.description);
            setFormTitleValue(props.item.title);
            setFormPointsValue(props.item.points);
            setFormIsChallenge(props.item.type === 'challenge');
            setFormCategoryValue({name: props.item.category}); // get category(props.item.category?)
        }
    }, [props.item])

    const [canSubmit, setCanSubmit] = useState(false);
    const [formTitleValue, setFormTitleValue] = useState("");
    const [formDescriptionValue, setFormDescriptionValue] = useState("");
    const [formIsChallengeValue, setFormIsChallenge] = useState(false);
    const [formCategoryValue, setFormCategoryValue] = useState({name: "active"});
    const [formCategoryOptions, setFormCategoryOptions] = useState([]);
    const [formTypeValue, setFormTypeValue] = useState({name: "active"});
    const [formTypeOptions, setFormTypeOptions] = useState([{type: "challenge"}, {type: "normal"}, {type: "daily"}]);
    const [formPointsValue, setFormPointsValue] = useState(1);
    const [showForm, setShowForm] = useState(false);
    // const [searchValue, setSearchValue] = useState("");
    // const [searchCategory, setSearchCategory] = useState("");

    // const CATEGORYBUTTONS = props.categories.map((category) => {
    //     return <a className="filterButton" label={category.name} style={{color: category.color, borderColor: category.color}} onClick={() => props.setSearchCategory(category.name)}>{category.name}</a>
    // })
    // CATEGORYBUTTONS.push(<a className="filterButton" label="CLEAR FILTER" onClick={() => props.setSearchCategory("")}>CLEAR FILTER</a>)
    const LISTITEMS = props.items.map((item) => {
        if(props.searchCategory === "") {
            if((item.title.toLowerCase().includes(props.searchValue.toLowerCase()) || item.description.toLowerCase().includes(props.searchValue.toLocaleLowerCase()))) {
                return (<Activity key={item._id} activity={item} edit={props.actions.showItem} delete={props.deleteObject}/>)
            }
        }
        if(props.searchCategory === item.category) {
            if((item.title.toLowerCase().includes(props.searchValue.toLowerCase()) || item.description.toLowerCase().includes(props.searchValue.toLocaleLowerCase()))) {
                return (<Activity key={item._id} activity={item} edit={props.actions.showItem} delete={props.deleteObject}/>)
            }
        } 
        
        

    });

    const enableButton = () => {
        setCanSubmit(true);
    }

    const disableButton = () => {
        setCanSubmit(false);
    }
    const hideForm = () => {
        props.clearCurrent();
        setShowForm(false);
        setFormDescriptionValue("");
        setFormTitleValue("");
        setFormPointsValue(1);
    }

    const formSubmit = (model, formAction) => {
        model.category = model.category.name;
        switch(formAction) {
            case 'add':
                props.addObject(model);
                break;
            case 'edit':
                props.editObject(model);
        }
        
        hideForm();
        // props.clearCurrent();
    }

    
    const setFormIsChallengeHandler = () => {
        if(formIsChallengeValue) {
            setFormIsChallenge(false)
        } else {
            setFormIsChallenge(true);
        }
    }

    const renderForm = (formAction) => {
       
        const footer = (
            <div className="footer">
    
                <Button onClick={hideForm} 
                        style={{marginLeft: '5px'}} 
                        label="cancel"
                        icon="pi pi-times"
                        className="p-button-danger"
                        />
                <Button  
                        style={{marginLeft: '5px'}} 
                        label="save"
                        icon="pi pi-check"
                        className="p-button-info"
                        disabled={!canSubmit}
                        type="submit"
                        />
                
            </div>
    
        )
        return (
            <div className="Activities">
                
            <Formsy onValidSubmit={(model) => formSubmit(model, formAction)} 
                        onValid={() => enableButton()}
                        onInvalid={() => disableButton()}
                        >
                <Dialog style={{width: '90%'}}
                        header="Edit Activity"
                        footer={footer}
                        visible
                        onHide={hideForm}
                        >
                <FormsyElement 
                    name="title"
                    value={formTitleValue}
                    validations="minLength:3"
                    validationError="Too Short"
                    required>
                    <InputText
                        value={formTitleValue}     
                        onChange={(e) => setFormTitleValue(e.target.value)}
                        placeholder="Short Title"
                        
                    />
                </FormsyElement>
                <FormsyElement 
                    name="description"
                    value={formDescriptionValue}
                    validations="minLength:10"
                    validationError="Too Short"
                    required>
                    <InputText
                        value={formDescriptionValue}     
                        onChange={(e) => setFormDescriptionValue(e.target.value)}
                        placeholder="Description"
                        
                    />
                </FormsyElement>
                <FormsyElement name="category"
                                value={formCategoryValue}
                                required>
                        <Dropdown optionLabel="name"
                                    value={formCategoryValue}
                                    options={formCategoryOptions}
                                    onChange={(e) => {setFormCategoryValue(e.value)}}
                                    placeHolder="Choose Category" />
                
                </FormsyElement>
                <FormsyElement 
                    name="points"
                    value={formPointsValue}
                    
                    >
                    <Spinner
                        value={formPointsValue}     
                        onChange={(e) => setFormPointsValue(e.target.value)}
                        placeholder="Description"
                        min={0}
                        max={200}
                        
                    />
                </FormsyElement>
                <FormsyElement name="type"
                                value={formTypeValue['type']}
                                required>
                        <Dropdown optionLabel="type"
                                    value={formTypeValue}
                                    options={formTypeOptions}
                                    onChange={(e) => {setFormTypeValue(e.value)}}
                                    placeHolder="Choose Type" />
                
                </FormsyElement>
                {/* <FormsyElement 
                    name="isChallenge"
                    value={formIsChallengeValue}
                    
                    required>
                        
                    <ToggleButton 
                            checked={formIsChallengeValue}
                            onChange={setFormIsChallengeHandler}
                            tooltip="Challenge"
                            onLabel="Challenge"
                            offLabel="Activity"
                    />
                </FormsyElement> */}
                </Dialog>
            </Formsy>
            </div>
           
        )
    }

    

    if(Object.entries(props.item).length > 0 || showForm) {
        let formAction = 'edit';
        if(showForm) { // Clicked Add
            formAction = 'add';
        }
        return renderForm(formAction);
        
    }

    return ( 
        <div className="Activities">
            <div className="search">
                <InputText placeholder="Type Something" value={props.searchValue} onChange={(e) => props.setSearchValue(e.target.value)} />
                <span className="categoryButtons p-grid">{props.CATEGORYBUTTONS}</span>
            </div>
            <ul>
            {LISTITEMS}
            </ul>
            <a className="addNewButton" onClick={() => setShowForm(true)}>
            <i class="pi pi-plus"></i>
            </a>
        </div>
     );
}
 
export default Activities;