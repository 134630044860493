import React from 'react';
import './UserDetail.css'

const UserDetail = (props) => {
    if(!props.user) {
        return <p>Loading...</p>
    } 
        return ( 
            <span>
            {props.user.username}
            </span>
    
         );

    
}
 
export default UserDetail;