import React from 'react';
import './Contender.css';
import flag_au from '../../../../../assets/images/au.png';
import flag_nz from '../../../../../assets/images/nz.png';
import flag_uk from '../../../../../assets/images/uk.png';

const Contender = (props) => {
    const flagMap = {
        "720": flag_nz,
        "600": flag_au,
        "60": flag_uk,
        "0": flag_uk,
        "Unknown": flag_nz
    }

    const styles = {
        li: {
            listStyle: 'none',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        }
    }

    console.log(props.contender)
    return ( 
        <li style={styles.li} className="Contender">
        <a onClick={() => props.click(props.contender)}>{props.contender.username}</a>
            <img src={flagMap[props.contender.profile.tz_offset]} style={{width: '30px'}} />
        </li>
     );
}
 
export default Contender;